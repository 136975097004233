import configSelector from "../../external/configSelector";
import Card from "@urbanpiper-engineering/meraki-components/dist/Atoms/Card";
import { getServerSideProps as getInitialProps } from "../../hooks/server.props";
import styles from "./custom.module.scss";

// export async function getStaticPaths() {
//   const customPages = configSelector.getCustomPages();
//   return {
//     paths: [
//       ...customPages.map((page) => ({
//         params: {
//           slug: page.path.substring(1),
//         },
//       })),
//     ],
//     fallback: false,
//   };
// }

export async function getServerSideProps(ctx: any) {
  const slug = ctx.params.slug;
  const customPages = configSelector.getCustomPages();
  const intialProps = await getInitialProps(ctx);

  const page = customPages.find((page: any) => page.path.substring(1) === slug);

  return {
    props: {
      ...intialProps.props,
      page,
    },
  };
}

function CustomPage({ page }: any) {
  return (
    <div className={styles.custom}>
      <Card>
        <div dangerouslySetInnerHTML={{ __html: page?.content }} />
      </Card>
    </div>
  );
}

export default CustomPage;
